import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>🚧 Under Construction 🚧</h1>
        <p>Onze website ondergaat een remake. Stay tuned!</p>
        <p>Ondertussen kan je ons bereiken via telefoon: 0479/279 716 of via mail: info@laborgata.be</p>
        <p>Afhalingen kunnen gebeuren op volgend adres: Kapellestraat 27, 9890 Gavere-Asper </p>
      </header>
    </div>
  );
}

export default App;
